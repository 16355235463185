<template>
    <div class="order-list">
        <normal-table
            ref="normalTable"
            method="post"
            :tableAction="tableAction"
            :url="url"
            :extra-query="{}"
            hasResetBtn
            :searchForm.sync="form"
            :page-size="200"
            :has-search-form="true"
            @select="select"
            @select-all="selectAll"
            @loaded="loaded"
        >
            <template slot="formItem">
                <el-form-item label="文章状态">
                    <el-select v-model="form.status" placeholder="类型" style="width:70px">
                        <el-option label="新建" value="new"></el-option>
	                    <el-option label="待发布" value="waitRelease"></el-option>
                        <el-option label="已发布" value="released"></el-option>
                    </el-select>
                </el-form-item>
            </template>
            <div slot="otherContent" class="otherContent">
                <div class="tableAction">
<!--                    <el-popconfirm title="选中的文章都将自动发布" @confirm='releaseAllArticle()' style="margin-left:5px;">-->
<!--                        <el-button slot="reference" type="primary">一键发布</el-button>-->
<!--                    </el-popconfirm>-->
                </div>
            </div>
        </normal-table>
    </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";

export default {
  name: 'localImgList',
    data() {
        return {
            total: 0,
            otherMap: {totalAccount:0},
            dateRange: [],
            form: {
                status: ''
            },
            url: "/client/gen/getArticleList",
            tableAction: {
                label: "操作",
                prop: "handle",
                align: "center",
                formatter: ({_id, status}) => {
                    return (
                        <div class="action">
	                        <el-button type="primary" class="item" onClick={() => this.preview(_id)}>预览</el-button>

	                        <el-popconfirm title="确定删除这个文章吗？" onConfirm={() => this.delete(_id)}>
		                        <el-button slot="reference" type="primary">删除</el-button>
	                        </el-popconfirm>

                        </div>
                    );
                },
            },
            selectList: [],
        };
    },

    components: {
        NormalTable,
    },

    computed: {},

    mounted() {
        // this.form.buyAbleDate = this.getNowFormatDate()
    },

    watch: {
    },

    methods: {
        // 获取接口数据
        loaded(data) {
            // console.log(data)
        },
        select(selection) {
            this.selectList = selection;
            console.log(selection);
        },
        selectAll(selection) {
            this.selectList = selection;
        },

		    release(ids) {
			    this.$http.post("/client/gen/release", {ids: ids.join(',')}).then((res) => {
				    if (res.code === 0) {
					   
							this.$message({
						    type: "success",
						    message: "发布成功",
					    });
							
					    this.$refs.normalTable.refresh();
				    } else {
					    this.$message({
						    type: "error",
						    message: res.msg,
					    });
				    }
				    this.$refs.normalTable.getList()
			    });
		    },
        resetForm() {
            this.dateRange = []
            this.form = {}
        },
        setOtherMap(map) {
            this.otherMap = map
        },
	      preview(_id) {
		      this.$router.push({path: "/generate/preview", query: {_id}}).then(() => {
			      try {
				      let matchedRoute = this.$route.matched.find(route => route.path === '/generate/preview');
				      if (matchedRoute.instances.default) matchedRoute.instances.default.initArticle()
			      } catch (e) {
				      console.log(e)
			      }
		      });
	      },
	    delete(id) {
		    this.$http.post("/client/gen/delete", {id}).then((res) => {
			    if (res.code === 0) {
				    this.$message({
					    type: "success",
					    message: "删除成功",
				    });
				    this.$refs.normalTable.refresh();
			    } else {
				    this.$message({
					    type: "error",
					    message: res.message,
				    });
			    }
		    }).finally(() => {
			    this.$refs.normalTable.getList()
        });
	    }
    },
};
</script>
<style lang="scss" scoped>
.el-form-item {
    flex-wrap: nowrap;
}
.order-list {
    .total {
        font-size: 14px;
        color: #333;
        text-align: right;
        padding-right: 30px;
    }
    .input-amount{
        display: inline-block;
        width: 100px;
    }
    .phone {
        width: 115px;
    }
    .day {
        width: 60px;
    }
}
.otherContent {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    flex-wrap: wrap;
    .tableAction {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: 5px;
        .el-button {
            margin-bottom: 5px;
        }
    }
    .allAccount {
        display: grid;
        place-items: end;
    }
}
::v-deep .el-message-box {
    max-width: 100% !important;
}
.el-message-box__message {
    max-width: 100% !important;
}
.action {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	.item {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}
}
</style>
<style>
.el-message-box {
    max-width: 100% !important;
}
</style>